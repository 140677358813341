import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatMenu, MatMenuContent, MatMenuTrigger } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { BaseDialogComponent } from '../../dialogs/base-dialog/base-dialog.component';
import { ComponentState } from '@futura/futura-ui/ghost';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss'],
  standalone: true,
  imports: [MatIcon, MatMenuTrigger, NgClass, MatMenu, BaseDialogComponent, NgTemplateOutlet, MatMenuContent],
})
export class InfoPanelComponent {
  @Input() public state?: ComponentState;

  @Input() public infoIcon = 'info';
  @Input() public fontSet = 'material-icons-outlined';
  @Input() public infoTitle?: string;
  @Input() public infoTemplate?: TemplateRef<unknown>;

  @ViewChild(MatMenuTrigger) private menuTrigger?: MatMenuTrigger;

  public toggleMenu(): void {
    if (!this.infoTemplate) {
      return;
    }
    this.menuTrigger?.toggleMenu();
  }
}
