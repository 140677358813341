@if (infoTemplate) {
  @if (!state || state === 'NONE') {
    <mat-icon class="text-opacity" [matMenuTriggerFor]="infoMenu" [fontSet]="fontSet">
      {{ infoIcon }}
    </mat-icon>
  } @else {
    <mat-icon class="fut-skeleton ghost-menu" [ngClass]="{ ghost: state === 'GHOST' }"></mat-icon>
  }
  <mat-menu #infoMenu backdropClass="fut-menu-info-bg" class="fut-menu-info-content">
    <ng-template matMenuContent>
      <app-base-dialog [dialogTitle]="infoTitle" [infoMenu]="true">
        <ng-container *ngTemplateOutlet="infoTemplate || null"></ng-container>
      </app-base-dialog>
    </ng-template>
  </mat-menu>
}
